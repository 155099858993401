import { useWallet } from '@solana/wallet-adapter-react'
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui'
import { LAMPORTS_PER_SOL } from '@solana/web3.js'
import classNames from 'classnames'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap'
import { connect, useSelector } from 'react-redux'
//@ts-ignore
import hdgCoin from '../assets/coins/hdg-white.svg'
//@ts-ignore
import ushCoin from '../assets/coins/ush-white.svg'
//@ts-ignore
import logo from '../assets/logos/hedge-on-dark.svg'
import { numberWithCommas } from '../lib/Utils'
import { NetworkConnection, NetworkConnections } from '../on-chain/utils/NetworkConnections'
import { Cluster } from '../on-chain/utils/OracleAccounts'
import { explorerThunk, phantomNetworkThunk } from '../reducers/walletReducer'
import Balance from './Balance'
import { RootState } from '../pages/_app'

import mixpanel from 'mixpanel-browser'
import { NotifiModal } from './Notifi'
import { Explorer, Explorers } from '../on-chain/utils/Explorers'

function NavBar({
  dispatch,
  networkConnection,
  explorer,
  showWallet,
  variant,
  userSolBalance,
  userUshBalance,
  userHedgeBalance,
  userVaults,
}) {
  const router = useRouter()
  const { publicKey: userWalletPublicKey } = useWallet()

  const setExplorer = (explorer: Explorer) => {
    dispatch(explorerThunk(explorer))
  }

  const setNetwork = (networkConnection: NetworkConnection) => {
    dispatch(phantomNetworkThunk(networkConnection))
  }

  const recentPrices = useSelector((state: RootState) => state.wallet.recentPrices)
  const hdgPrice = useSelector((state: RootState) => state.wallet.hdgPrice)
  const ushPrice = useSelector((state: RootState) => state.wallet.ushPrice)

  const prices = {}
  if (recentPrices?.get('SOL') && hdgPrice && ushPrice) {
    prices['SOL'] = numberWithCommas(recentPrices.get('SOL'), 2)
    prices['HDG'] = numberWithCommas(hdgPrice, 2)
    prices['USH'] = numberWithCommas(ushPrice, 4)
  }

  return (
    <>
      <Navbar collapseOnSelect expand="lg" bg="" variant="dark">
        <Container>
          <Link href="/" passHref>
            <Navbar.Brand>
              <Image src={logo} width="130" height="43" />
            </Navbar.Brand>
          </Link>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse>
            <Nav className="me-auto">
              <Link href="/borrow" passHref>
                <Nav.Link active={router.asPath.startsWith('/borrow')}>Borrow</Nav.Link>
              </Link>
              <Link href="/vaults" passHref>
                <Nav.Link active={router.asPath.startsWith('/vaults')}>
                  Vaults{userVaults && userVaults.length > 0 && <>&nbsp;({userVaults.length})</>}
                </Nav.Link>
              </Link>
              <Link href="/pools" passHref>
                <Nav.Link active={router.asPath.startsWith('/pools')}>Pools</Nav.Link>
              </Link>
              <Link href="/referral" passHref>
                <Nav.Link
                  active={router.asPath.startsWith('/referral')}
                  className="position-relative"
                >
                  Referral{' '}
                  <span className="position-absolute top-0 start-50 translate-middle badge rounded-pill bg-primary small">
                    NEW
                  </span>
                </Nav.Link>
              </Link>
              <NavDropdown title="More">
                <Link href="https://docs.hedge.so/" passHref>
                  <NavDropdown.Item>Docs</NavDropdown.Item>
                </Link>
                <Link href="/stats" passHref>
                  <NavDropdown.Item>Stats</NavDropdown.Item>
                </Link>
                <Link href="/redeem" passHref>
                  <NavDropdown.Item>Redeem</NavDropdown.Item>
                </Link>
                <Link href="/liquidate" passHref>
                  <NavDropdown.Item>Liquidate</NavDropdown.Item>
                </Link>
                <Link href="/psm" passHref>
                  <NavDropdown.Item>Peg Stability Module</NavDropdown.Item>
                </Link>
                <Link href="https://flex.zeta.markets/dashboard" passHref>
                  <NavDropdown.Item>Exercise HDG options</NavDropdown.Item>
                </Link>
              </NavDropdown>
            </Nav>
            <NotifiModal />

            <Nav className="align-items-center">
              <NavDropdown
                title={`${networkConnection.cluster.toUpperCase()} - ${
                  networkConnection.networkName
                }`}
                className="d-block hedge-connections"
              >
                <li>
                  <h6 className="dropdown-header">MAINNET-BETA</h6>
                </li>
                {NetworkConnections.filter((connection) => {
                  return connection.cluster === Cluster.Mainnet
                }).map((connection) => {
                  return (
                    <NetworkDropdownItem
                      key={connection.networkUrl}
                      currentNetworkUrl={networkConnection.networkUrl}
                      handleClick={setNetwork}
                      networkConnection={connection}
                    />
                  )
                })}
                <li>
                  <h6 className="dropdown-header">DEVNET</h6>
                </li>
                {NetworkConnections.filter((connection) => {
                  return connection.cluster === Cluster.Devnet
                }).map((connection) => {
                  return (
                    <NetworkDropdownItem
                      key={connection.networkUrl}
                      currentNetworkUrl={networkConnection.networkUrl}
                      handleClick={setNetwork}
                      networkConnection={connection}
                    />
                  )
                })}
              </NavDropdown>
              <Nav className="align-items-center">
                <NavDropdown
                  title={<i className="fa-light fa-gear" />}
                  className="d-block hedge-settings"
                >
                  <li>
                    <h6 className="dropdown-header">DEFAULT EXPLORER</h6>
                  </li>
                  {Explorers.map((exp) => {
                    return (
                      <ExplorerDropdownItem
                        key={exp.baseUrl}
                        currentExplorerBaseUrl={explorer.baseUrl}
                        handleClick={setExplorer}
                        explorer={exp}
                      />
                    )
                  })}
                </NavDropdown>
              </Nav>
              <Nav.Link eventKey={2}>
                <span
                  onClick={(e) => {
                    mixpanel.track('Clicked connect wallet')
                  }}
                >
                  <WalletMultiButton className="d-flex" />
                </span>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Container>
        {userWalletPublicKey && (
          <WalletViewer
            userSolBalance={userSolBalance}
            userUshBalance={userUshBalance}
            userHedgeBalance={userHedgeBalance}
            prices={prices}
          />
        )}
      </Container>
    </>
  )
}

function mapStateToProps(state) {
  const {
    networkConnection,
    explorer,
    userSolBalance,
    userUshBalance,
    userHedgeBalance,
    userVaults,
  } = state.wallet
  return {
    networkConnection,
    explorer,
    userSolBalance,
    userUshBalance,
    userHedgeBalance,
    userVaults,
  }
}
export default connect(mapStateToProps)(NavBar)

function ExplorerDropdownItem({ currentExplorerBaseUrl, handleClick, explorer }) {
  const active = currentExplorerBaseUrl === explorer.baseUrl
  return (
    <NavDropdown.Item onClick={() => handleClick(explorer)} className={classNames({ active })}>
      <span>{explorer.name}</span>
      <br />
      <small>
        <span className={classNames('text-light opacity-50', { 'fw-bold': active })}>
          {explorer.baseUrl}
        </span>
      </small>
    </NavDropdown.Item>
  )
}

function NetworkDropdownItem({ currentNetworkUrl, handleClick, networkConnection }) {
  const active = currentNetworkUrl === networkConnection.networkUrl
  return (
    <NavDropdown.Item
      onClick={() => handleClick(networkConnection)}
      className={classNames({ active })}
    >
      <span>{networkConnection.networkName}</span>
      <br />
      <small>
        <span className={classNames('text-light opacity-50', { 'fw-bold': active })}>
          {networkConnection.networkUrl}
        </span>
      </small>
    </NavDropdown.Item>
  )
}

function WalletViewer({ userSolBalance, userUshBalance, userHedgeBalance, prices }) {
  return (
    <div
      className="d-flex align-items-center py-2 justify-content-center justify-content-lg-end"
      style={{ lineHeight: '20px' }}
    >
      <div className="me-2">
        <Balance
          amount={userSolBalance / LAMPORTS_PER_SOL}
          decimals={2}
          collateral="SOL"
          tooltip={'SOL price: $' + prices['SOL'] + ' USD'}
        />
      </div>
      <div className="me-2">
        <Balance
          amount={userUshBalance / LAMPORTS_PER_SOL}
          decimals={2}
          collateral="USH"
          tooltip={'USH price: $' + prices['USH'] + ' USD'}
        />
      </div>
      <div className="me-2">
        <Balance
          amount={userHedgeBalance / LAMPORTS_PER_SOL}
          decimals={2}
          collateral="HDG"
          tooltip={'HDG price: $' + prices['HDG'] + ' USD'}
        />
      </div>
    </div>
  )
}
