import {
  AlertConfiguration,
  broadcastMessageConfiguration,
  hedgeProtocolConfiguration,
  NotifiCard,
  NotifiEmailInput,
  NotifiFooter,
  NotifiSmsInput,
  NotifiTelegramInput,
  useNotifiSubscribe,
  useNotifiSubscriptionContext
} from '@notifi-network/notifi-react-card'

import type { MessageSigner } from '@notifi-network/notifi-core'
import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'

import { useConnection, useWallet } from '@solana/wallet-adapter-react'
import { Form, FormCheck, InputGroup } from 'react-bootstrap'

const ALERT_NAME = 'My Marketing Updates'
const ALERT_CONFIGURATION: AlertConfiguration = broadcastMessageConfiguration({
  topicName: 'hedge.so__marketing',
})

type Props = Readonly<{
  className?: string
  disabled: boolean
}>

export const NotifiCardContents: React.FC = () => {
  const [enabled, setEnabled] = useState<boolean>(false)
  const { setAlertConfiguration, useHardwareWallet, setUseHardwareWallet } =
    useNotifiSubscriptionContext()
  const { loading, subscribe } = useNotifiSubscribe()
  const [success, setSuccess] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)

  useEffect(() => {
    if (enabled) {
      setAlertConfiguration(ALERT_NAME, ALERT_CONFIGURATION)
    } else {
      setAlertConfiguration(ALERT_NAME, null)
    }
  }, [enabled, setAlertConfiguration])

  return (
    <>
      <div className="m-3">
        Receive alerts whenever a liquidation occurs and USH in the Stability Pool got swapped for
        collateral.
      </div>
      <Form className="m-3">
        <NotifiEmailInput
          classNames={{
            container: 'input-group mb-3',
            input: 'form-control',
          }}
          disabled={loading}
        />
        <NotifiSmsInput
          classNames={{
            container: 'input-group mb-3',
            input: 'form-control',
          }}
          disabled={loading}
        />
        <NotifiTelegramInput
          classNames={{
            container: 'input-group mb-3',
            input: 'form-control',
          }}
          disabled={loading}
        />
        <InputGroup className="d-flex flex-row">
          <Form.Label className="fs-sm-1 me-2 d-block">
            Sign up for product updates and alerts
          </Form.Label>
          <FormCheck
            disabled={loading}
            type="checkbox"
            checked={enabled}
            onChange={(e) => {
              setEnabled(e.target.checked)
            }}
          />
        </InputGroup>
        <InputGroup className="d-flex flex-row mb-3">
          <Form.Label className="fs-sm-1 me-2 d-block">Sign up with hardware wallet</Form.Label>
          <FormCheck
            disabled={loading}
            type="checkbox"
            checked={useHardwareWallet}
            onChange={(e) => {
              setUseHardwareWallet(e.target.checked)
            }}
          />
        </InputGroup>
        <Button
          disabled={loading}
          type="submit"
          onClick={async () => {
            try {
              await subscribe()
              setSuccess(true)
              setError(false)
            } catch (error) {
              setSuccess(false)
              setError(true)
              console.log(error)
            }
          }}
        >
          Subscribe
        </Button>
        {success || error ? (
          <span className="m-2"> {success ? 'Success!' : 'Something went wrong!'} </span>
        ) : (
          ''
        )}
      </Form>
      <NotifiFooter
        classNames={{
          container: 'd-flex flex-row m-3',
          logoSvg: 'notifi-logo-svg',
          poweredBy: 'flex-shrink-0',
          spacer: 'flex-grow-1',
        }}
      />
    </>
  )
}

export const Notifi: React.FC<{ walletAddress: string }> = ({ walletAddress }) => {
  const { wallet } = useWallet()
  const adapter = wallet?.adapter
  const publicKey = adapter?.publicKey?.toBase58() ?? null
  const { connection } = useConnection()

  const alertConfigurations: Record<string, AlertConfiguration | null> = {}
  alertConfigurations[walletAddress] = hedgeProtocolConfiguration({ walletAddress: walletAddress })

  return (
    <NotifiCard
      dappAddress="hedge.so"
      env="Production"
      signer={adapter as MessageSigner}
      walletPublicKey={publicKey}
      alertConfigurations={alertConfigurations}
      connection={connection}
      sendTransaction={(txn, cxn, opt) => {
        return adapter.sendTransaction(txn, cxn, opt)
      }}
      classNames={{
        disconnected: {
          container: 'm-3',
        },
      }}
    >
      <NotifiCardContents />
    </NotifiCard>
  )
}

export const NotifiModal: React.FC = () => {
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const { wallet } = useWallet()
  const adapter = wallet?.adapter
  const publicKey = adapter?.publicKey?.toString() ?? null

  return (
    <>
      <div onClick={handleShow} className="me-2" role="button">
        <i className="fa-thin fa-bell" />
      </div>

      <Modal
        className="notifi-modal"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        contentClassName="notifi-modal-content"
        centered
      >
        <Modal.Header className="notifi-modal-header" closeButton>
          <Modal.Title>Subscribe to Notifications</Modal.Title>
        </Modal.Header>
        <Notifi walletAddress={publicKey} />
      </Modal>
    </>
  )
}
