import NavBar from './NavBar'
import Footer from './Footer'
import EmergencyModeBanner from './EmergencyModeBanner'
import TimeWarningBanner from './banners/TimeWarningBanner'
import PsaAlertBanner from './banners/PsaAlertBanner'

const radialBackground = {
  position: 'absolute',
  width: '542px',
  height: '542px',
  left: '-519.09px',
  top: '-158px',

  background: 'linear-gradient(132.09deg, #FA9623 36.18%, rgba(196, 27, 200, 0.92) 69.95%)',
  filter: 'blur(363.898px)',
  transform: 'rotate(10.41deg)',
  opacity: 0.2,
} as React.CSSProperties

export default function MainLayout(props) {
  return (
    <>
      <div style={radialBackground}></div>
      <div className="main-body">
        <main className="text-white">
          <PsaAlertBanner />
          <TimeWarningBanner />
          <EmergencyModeBanner />
          <NavBar showWallet variant="dark" />
          <div className="py-3">{props.children}</div>
        </main>
        <Footer />
      </div>
    </>
  )
}
