import { Container } from 'react-bootstrap'
import Link from 'next/link'

function Footer() {
  return (
    <div className="footer">
      <Container className="">
        <footer className="d-flex flex-wrap justify-content-between align-items-center py-3 my-4">
          <p className="col-md-4 mb-0 text-muted">© 2022 Hedge Labs</p>

          <ul className="nav col-md-4 justify-content-end">
            <li className="nav-item">
              <Link href="/careers" passHref>
                <a className="nav-link px-2 text-muted">Careers</a>
              </Link>
            </li>
            <li className="nav-item">
              <Link href="https://discord.gg/qfqegrRWmw" passHref>
                <a className="nav-link px-2 text-muted" target="_blank">
                  <i className="fab fa-discord" />
                </a>
              </Link>
            </li>
            <li className="nav-item">
              <Link href="https://hedgelabs.medium.com/" passHref>
                <a className="nav-link px-2 text-muted" target="_blank">
                  <i className="fab fa-medium" />
                </a>
              </Link>
            </li>
            <li className="nav-item">
              <Link href="https://twitter.com/HedgeLabs" passHref>
                <a className="nav-link px-2 text-muted" target="_blank">
                  <i className="fab fa-twitter" />
                </a>
              </Link>
            </li>
            <li className="nav-item">
              <Link href="https://www.linkedin.com/company/80037401" passHref>
                <a className="nav-link px-2 text-muted" target="_blank">
                  <i className="fab fa-linkedin" />
                </a>
              </Link>
            </li>
          </ul>
        </footer>
      </Container>
    </div>
  )
}
export default Footer
