import Link from 'next/link'
import { Card, Container } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { RootState } from '../pages/_app'

export default function EmergencyModeBanner() {
  const recentPrices = useSelector((state: RootState) => state.wallet.recentPrices)
  const vaultTypes = useSelector((state: RootState) => state.wallet.vaultTypes)
  const typesInEmergencyMode = vaultTypes.filter((vaultType) => {
    const currentPrice = recentPrices.get(vaultType.collateral)
    return vaultType.isEmergencyMode(currentPrice)
  })

  return (
    <>
      {typesInEmergencyMode.map((vaultType) => {
        const price = recentPrices.get(vaultType.collateral)

        if (!price) {
          return <div key={vaultType.publicKey.toString()}></div>
        }

        return (
          <Container className="pt-3" key={vaultType.publicKey.toString()}>
            <div className="d-flex alert alert-warning">
              <div className="me-2">
                <i className="fa-thin fa-warning me-2 fa-lg" />
              </div>
              <div>
                <div>
                  {vaultType.title} is at a collateral ratio of{' '}
                  {vaultType.displayCurrentCollateralRatio(
                    recentPrices.get(vaultType.collateral)
                  )}{' '}
                  which is below the Recovery threshold of {vaultType.displayEmergencyThreshold()}{' '}
                </div>
                <div className="mt-2">
                  Taking out loans and withdrawing collateral is disabled. Any vault with a
                  collateral ratio less than {vaultType.displayEmergencyThreshold()} may be
                  liquidated until it is no longer under the recovery threshold. More details in the{' '}
                  <Link href="https://docs.hedge.so/protocol-overview/liquidation-and-liquidation-pool">
                    Hedge Docs
                  </Link>
                </div>
              </div>
            </div>
          </Container>
        )
      })}
    </>
  )
}
