import Image from 'next/image'
import { numberWithCommas } from '../lib/Utils'
//@ts-ignore
import SolanaCoin from '../public/solana-white.svg'
//@ts-ignore
import HdgCoin from '../assets/coins/hdg-white.svg'
//@ts-ignore
import UshCoin from '../assets/coins/ush-white.svg'
//@ts-ignore
import Usd from '../assets/coins/plus-symbol-white.svg'
//@ts-ignore
import MSolCoin from '../public/crypto-logos/msol.png'
//@ts-ignore
import WBTCCoin from '../public/crypto-logos/btc.png'
//@ts-ignore
import CUSDCCoin from '../public/crypto-logos/cusdc.png'
//@ts-ignore
import CUSDTCoin from '../public/crypto-logos/cusdt.png'
//@ts-ignore
import SOETHCoin from '../public/crypto-logos/eth.png'
import Decimal from 'decimal.js'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

type BalanceProps = {
  amount: number | Decimal
  collateral: string
  decimals?: number
  tooltip?: string | JSX.Element[]
}

export default function Balance({ amount, collateral, decimals = 2, tooltip = '' }: BalanceProps) {

  let overlay = <></>
  if (tooltip) {
    overlay = <Tooltip id="button-tooltip">{tooltip}</Tooltip>
  }
  return (
    <OverlayTrigger
      placement="bottom"
      delay={{ show: 250, hide: 400 }}
      overlay={overlay}
    >
      <div className="d-inline-flex flex-column align-items-end position-relative">
        <div
          className="d-flex align-items-center"
          style={{
            backgroundColor: '#39383A',
            borderRadius: 200,
            padding: '6px 12px 6px 6px',
          }}
        >
          <Image src={getIconForCollateral(collateral)} width={26} height={26} alt="" />
          <div className="p-0 m-0 ms-2">
            <span className="text-number">{numberWithCommas(amount, decimals)}</span>{' '}
            <span className="fw-bold">{collateral}</span>
          </div>
        </div>
      </div>
    </OverlayTrigger>
  )
}

export function getIconForCollateral(collateral: string) {
  switch (collateral) {
    case 'SOL':
      return SolanaCoin
    case 'USH':
      return UshCoin
    case 'HDG':
      return HdgCoin
    case 'mSOL':
      return MSolCoin
    case 'wBTC':
      return WBTCCoin
    case 'cUSDC':
      return CUSDCCoin
    case 'cUSDT':
      return CUSDTCoin
    case 'soETH':
      return SOETHCoin
    case 'USD':
      return Usd
  }
  return SolanaCoin
}
